import { METHOD, SEPA_ACCOUNT_OWNER, SEPA_BANK, SEPA_BIC, SEPA_IBAN, STRIPE_TOKEN } from "./field-names";
import { PaymentMethod } from "../../../../util/subscription/enums";
export const defaultValues = {
    [METHOD]: PaymentMethod.SEPA,
    [SEPA_ACCOUNT_OWNER]: "",
    [SEPA_BIC]: "",
    [SEPA_IBAN]: "",
    [SEPA_BANK]: "",
    [STRIPE_TOKEN]: ""
};
