import * as LocalStorage from './local-storage';
import * as SessionStorage from './session-storage';
export const hasItem = (cacheType, prefix, id) => {
    if (testStorage(cacheType)) {
        switch (cacheType) {
            case "LOCAL_STORAGE":
                return LocalStorage.hasItem(prefix, id);
            default:
                return SessionStorage.hasItem(prefix, id);
        }
    }
};
export const getItem = (cacheType, prefix, id) => {
    if (testStorage(cacheType)) {
        switch (cacheType) {
            case "LOCAL_STORAGE":
                return LocalStorage.getItem(prefix, id);
            default:
                return SessionStorage.getItem(prefix, id);
        }
    }
};
export const setItem = (cacheType, prefix, id, value) => {
    if (testStorage(cacheType)) {
        switch (cacheType) {
            case "LOCAL_STORAGE":
                LocalStorage.setItem(prefix, id, value);
                break;
            default:
                SessionStorage.setItem(prefix, id, value);
        }
    }
};
export const removeItem = (cacheType, prefix, id) => {
    if (testStorage(cacheType)) {
        switch (cacheType) {
            case "LOCAL_STORAGE":
                LocalStorage.removeItem(prefix, id);
                break;
            default:
                SessionStorage.removeItem(prefix, id);
        }
    }
};
export const buildKey = (prefix, id) => {
    return prefix + "::" + id;
};
export const testStorage = cacheType => {
    let storage;
    try {
        storage = cacheType == "LOCAL_STORAGE" ? window["localStorage"] : window["sessionStorage"];
        if (storage) {
            storage.setItem("test", "test");
            storage.removeItem("test");
            return true;
        }
        return false;
    }
    catch (e) {
        return false;
    }
};
