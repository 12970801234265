import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { getUserDocumentRef } from "./util";
import { AuthActionKeyType, StatusType } from "./auth-context/reducer";
import { arrayUnion, updateDoc } from "firebase/firestore";
export const saveReadArticle = async (articleId, authContext) => {
    const [{ authenticated, user, userData }, dispatch] = authContext;
    if (authenticated) {
        const userDocumentRef = getUserDocumentRef(user.uid);
        await updateDoc(userDocumentRef, {
            readArticles: arrayUnion(articleId)
        });
        // Update context so we don't have to fetch data from firebase
        if (userData.status === StatusType.SUCCESS) {
            const readArticles = [].concat(_toConsumableArray(userData.data.readArticles), [articleId]);
            dispatch({
                type: AuthActionKeyType.USER_DATA_SUCCESS,
                payload: {
                    data: {
                        ...userData.data,
                        readArticles
                    }
                }
            });
        }
    }
};
