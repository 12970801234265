import FirebaseHandler from "../../firebase";
import { removeItem } from "../../cache/cache";
import { httpsCallable } from "firebase/functions";
export const SUBSCRIPTION_INFO_NEXT_DATE_CACHE_ID = "nextDate";
export const setSubscriptionInfo = async (email) => {
    const setSubscriptionInfo = httpsCallable(FirebaseHandler.functions, "setSubscriptionInfo");
    return setSubscriptionInfo({
        email
    });
};
export const removeSubscriptionInfoNextDateFromCache = () => {
    removeItem("LOCAL_STORAGE", "SUBSCRIPTION_INFO", SUBSCRIPTION_INFO_NEXT_DATE_CACHE_ID);
};
