import { buildKey } from "./cache";
export const hasItem = (prefix, id) => {
    return sessionStorage.getItem(buildKey(prefix, id)) !== null;
};
export const getItem = (prefix, id) => {
    return JSON.parse(sessionStorage.getItem(buildKey(prefix, id)));
};
export const setItem = (prefix, id, value) => {
    sessionStorage.setItem(buildKey(prefix, id), JSON.stringify(value));
};
export const removeItem = (prefix, id) => {
    sessionStorage.removeItem(buildKey(prefix, id));
};
