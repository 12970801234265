const token = "e86605afcc1c9e04add74241869e5b";
export const datoCmsId = fullId => removeAfterLastOccurrence(removeBeforeCharacter(fullId, "-"), "-");
export const gatsbyDatoCmsId = (id, modelName) => `DatoCms${modelName}-${id}-de`;
export const bookmarkedArticleQuery = bookmarks => `{
     allArticles(
        filter: { id: { in: ${JSON.stringify(bookmarks)} } }
    ) {
      excerpt
      id
      title
      smallTitle
      slug
      authors {
        name
      }
      image {
        responsiveImage {
          base64
          aspectRatio
          width
          height
          src
          srcSet
          sizes
        }
      }
     }
   }`;
export const fetchFromDatoCms = query => fetch("https://graphql.datocms.com", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
        query
    })
});
function removeBeforeCharacter(str, char) {
    const index = str.indexOf(char);
    if (index !== -1) {
        return str.substring(index + 1);
    }
    return str;
}
function removeAfterLastOccurrence(str, char) {
    const lastIndex = str.lastIndexOf(char);
    if (lastIndex !== -1) {
        return str.substring(0, lastIndex);
    }
    else {
        return str;
    }
}
