import FirebaseHandler from "./index";
import { datoCmsId } from "../dato-cms";
import { doc, getDoc } from "firebase/firestore";
export const STORAGE_URL = "gs://makroskop-b14f3.appspot.com";
export const getUserDocumentRef = userId => {
    return doc(FirebaseHandler.firestore, "users", userId);
};
export const loadUserDocument = userId => {
    return getDoc(getUserDocumentRef(userId));
};
export const loadUserData = async (userId) => {
    let data;
    try {
        const doc = await loadUserDocument(userId);
        data = doc.data();
    }
    catch (err) {
        console.error("Error in loadUserData", err);
    }
    return {
        bookmarks: data && data.bookmarks ? data.bookmarks : [],
        readArticles: data && data.readArticles ? data.readArticles : [],
        newsletterSubscribed: data && data.newsletterSubscribed ? data.newsletterSubscribed : false,
        customerId: data && data.customer_id ? data.customer_id : undefined
    };
};
export const loadArticleDocument = articleId => {
    return getDoc(doc(FirebaseHandler.firestore, "articles", articleId));
};
export const loadArticleData = async (articleId) => {
    let data;
    try {
        const doc = await loadArticleDocument(datoCmsId(articleId));
        data = doc.data();
    }
    catch (err) {
        console.error("Error in loadArticleData", err);
    }
    return {
        pixel: data && data.pixel ? data.pixel : undefined,
        sample: data && data.textSample ? data.textSample : undefined
    };
};
export const loadUserClaims = async () => {
    var _idTokenResult, _idTokenResult$claims, _idTokenResult2, _idTokenResult2$claim, _idTokenResult3, _idTokenResult3$claim, _idTokenResult4, _idTokenResult4$claim;
    let idTokenResult;
    try {
        idTokenResult = await FirebaseHandler.auth.currentUser.getIdTokenResult();
        /*
        FirebaseHandler.auth.currentUser.getIdTokenResult() // TODO
          .then(idTokenResult => console.log(idTokenResult.claims))
         */
    }
    catch (err) {
        console.error("Error in loadUserClaims", err);
    }
    return {
        customerId: (_idTokenResult = idTokenResult) === null || _idTokenResult === void 0 ? void 0 : (_idTokenResult$claims = _idTokenResult.claims) === null || _idTokenResult$claims === void 0 ? void 0 : _idTokenResult$claims.customer_id,
        subscriptionType: (_idTokenResult2 = idTokenResult) === null || _idTokenResult2 === void 0 ? void 0 : (_idTokenResult2$claim = _idTokenResult2.claims) === null || _idTokenResult2$claim === void 0 ? void 0 : _idTokenResult2$claim.subscriptionType,
        subscriptionStatus: (_idTokenResult3 = idTokenResult) === null || _idTokenResult3 === void 0 ? void 0 : (_idTokenResult3$claim = _idTokenResult3.claims) === null || _idTokenResult3$claim === void 0 ? void 0 : _idTokenResult3$claim.subscriptionStatus,
        subscriptionEnd: (_idTokenResult4 = idTokenResult) !== null && _idTokenResult4 !== void 0 && (_idTokenResult4$claim = _idTokenResult4.claims) !== null && _idTokenResult4$claim !== void 0 && _idTokenResult4$claim.subscriptionEnd ? new Date(idTokenResult.claims.subscriptionEnd * 1000) : undefined
    };
};
