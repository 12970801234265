import { useStaticQuery } from "gatsby";
import format from "date-fns/format/index";
import de from "date-fns/locale/de";
import { utcToZonedTime } from "date-fns-tz";
const useCmsEvents = () => {
    const data = useStaticQuery("2076357383");
    const edges = data.allDatoCmsEvent.edges.filter(edge => {
        const now = new Date();
        const utcDate = new Date(edge.node.time).toISOString();
        return now <= new Date(utcDate);
    }).map(edge => {
        const utcDate = new Date(edge.node.time).toISOString();
        const zonedDate = utcToZonedTime(utcDate, "Europe/Berlin");
        const formattedDate = format(zonedDate, "dd. MMMM yyyy,  HH:mm", {
            locale: de
        });
        return {
            node: {
                ...edge.node,
                time: formattedDate
            }
        };
    });
    return {
        allDatoCmsEvent: {
            edges
        }
    };
};
export default useCmsEvents;
