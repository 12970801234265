import { useStaticQuery } from "gatsby";
const useLatestEditionSpotlight = () => {
    const { allDatoCmsEdition, allDatoCmsSpotlight } = useStaticQuery("1534434256");
    return {
        edition: {
            articles: assembleEditionArticles(allDatoCmsEdition),
            firstPublishedAt: new Date(allDatoCmsEdition.nodes[0].meta.firstPublishedAt)
        },
        spotlight: {
            articles: assembleSpotlightArticles(allDatoCmsSpotlight),
            firstPublishedAt: new Date(allDatoCmsSpotlight.nodes[0].meta.firstPublishedAt)
        }
    };
};
export default useLatestEditionSpotlight;
const assembleEditionArticles = data => {
    if (!data.nodes || data.nodes.length <= 0)
        return null;
    const edition = data.nodes[0];
    return edition.sections.flatMap(section => section.articles.map(article => article.id)).concat(edition.editorial.id);
};
const assembleSpotlightArticles = data => {
    if (!data.nodes || data.nodes.length <= 0)
        return null;
    const spotlight = data.nodes[0];
    return spotlight.articles.map(article => article.id).concat(spotlight.briefing.id);
};
