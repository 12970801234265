import { STORAGE_URL } from "./util";
import FirebaseHandler from "./index";
import { getDownloadURL, ref } from "firebase/storage";
export const getAudioDownloadUrl = async (articleId, slug) => {
    const url = `${STORAGE_URL}/mp3/${articleId}_${slug}.mp3`;
    return await getDownloadURL(ref(FirebaseHandler.storage, url));
};
export const getPdfDownloadUrl = async (articleId, slug) => {
    const url = `${STORAGE_URL}/pdf/${articleId}_${slug}.pdf`;
    return await getDownloadURL(ref(FirebaseHandler.storage, url));
};
