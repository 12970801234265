import * as yup from "yup";
import { METHOD, SEPA_ACCOUNT_OWNER, SEPA_IBAN, SEPA_BIC, SEPA_BANK, STRIPE_TOKEN } from "./field-names";
import { INVALID_BIC, INVALID_IBAN, MISSING_STRIPE_TOKEN, REQUIRED_MESSAGE } from "../../validation-messages";
import { PaymentMethod } from "../../../../util/subscription/enums";
import { removeAllWhitespaces } from "../../../../util/forms/validation";
export const validationSchema = yup.object({
    [METHOD]: yup.string().required(),
    [SEPA_ACCOUNT_OWNER]: yup.string().when([METHOD], {
        is: PaymentMethod.SEPA,
        then: yup.string().required(REQUIRED_MESSAGE)
    }),
    [SEPA_IBAN]: yup.string().when([METHOD], {
        is: PaymentMethod.SEPA,
        then: yup.string().uppercase().transform((value, originalValue) => removeAllWhitespaces(value, originalValue)).required(REQUIRED_MESSAGE).matches(/([A-Z]{2})(\d\d)(\d\d\d\d\d\d\d\d\d\d\d\d+)/, INVALID_IBAN)
    }),
    [SEPA_BIC]: yup.string().when([METHOD], {
        is: PaymentMethod.SEPA,
        then: yup.string().uppercase().transform((value, originalValue) => removeAllWhitespaces(value, originalValue)).required(REQUIRED_MESSAGE).matches(/^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/, INVALID_BIC)
    }),
    [SEPA_BANK]: yup.string().when([METHOD], {
        is: PaymentMethod.SEPA,
        then: yup.string().required(REQUIRED_MESSAGE)
    }),
    [STRIPE_TOKEN]: yup.string().when([METHOD], {
        is: PaymentMethod.CARD,
        then: yup.string().required(MISSING_STRIPE_TOKEN)
    })
});
