import { subscribeToNewsletter } from "../../firebase/newsletter";
import FirebaseHandler from "../../firebase";
import { httpsCallable } from "firebase/functions";
export const orderFreiexemplar = async (data, newsletter) => {
    let result;
    try {
        var _orderResult$data;
        if (newsletter) {
            await subscribeToNewsletter(data.email);
        }
        const orderResult = await httpsCallable(FirebaseHandler.functions, "orderFreiexemplar")(data);
        result = {
            // @ts-ignore
            success: !!((_orderResult$data = orderResult.data) !== null && _orderResult$data !== void 0 && _orderResult$data.success)
        };
    }
    catch (err) {
        console.error(err);
        result = {
            success: false
        };
    }
    return result;
};
