import DOMWindow from "../util/dom-wrapper/window";
import useLatestEditionSpotlight from "./use-latest-edition-spotlight";
export const useIsEditionSpotlightMagazine = () => {
    const path = DOMWindow.locationPathname();
    let result = "is-edition";
    if (["", "/"].includes(path)) {
        const { edition, spotlight } = useLatestEditionSpotlight();
        if (new Date(spotlight.firstPublishedAt) > new Date(edition.firstPublishedAt)) {
            result = "is-spotlight";
        }
    }
    else {
        if (DOMWindow.locationPathname().startsWith("/themenhefte")) {
            result = "is-magazine";
        }
        else if (DOMWindow.locationPathname().startsWith("/spotlight")) {
            result = "is-spotlight";
        }
        else if (DOMWindow.locationPathname().startsWith("/alle-spotlights")) {
            result = "is-spotlight";
        }
    }
    return result;
};
