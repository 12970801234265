import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { getUserDocumentRef } from "./util";
import { AuthActionKeyType, StatusType } from "./auth-context/reducer";
import { arrayRemove, arrayUnion, updateDoc } from "firebase/firestore";
export const setBookmarked = async (articleId, bookmarked, authContext) => {
    const [{ authenticated, user }] = authContext;
    if (authenticated) {
        const userDocumentRef = getUserDocumentRef(user.uid);
        await updateDoc(userDocumentRef, {
            bookmarks: bookmarked ? arrayUnion(articleId) : arrayRemove(articleId)
        });
        // Update context so we don't have to fetch data from firebase
        await setBookmarkedContext(articleId, bookmarked, authContext);
    }
};
export const setBookmarkedContext = async (articleId, bookmarked, authContext) => {
    const [{ authenticated, userData }, dispatch] = authContext;
    if (authenticated && userData.status === StatusType.SUCCESS && userData.data && userData.data.bookmarks) {
        const bookmarks = bookmarked ? [].concat(_toConsumableArray(userData.data.bookmarks), [articleId]) : userData.data.bookmarks.filter(bookmark => bookmark !== articleId);
        dispatch({
            type: AuthActionKeyType.USER_DATA_SUCCESS,
            payload: {
                data: {
                    ...userData.data,
                    bookmarks
                }
            }
        });
    }
};
