import * as yup from "yup";
import { CUSTOMER, PAYMENT_METHOD, LEGAL_AGREEMENT } from "./field-names";
import { validationSchema as customerDataValidationSchema } from "../_sub-forms/customer-data/validation-schema";
import { validationSchema as paymentMethodValidationSchema } from "../_sub-forms/payment-method/validation-schema";
import { validationSchema as legalAgreementValidationSchema } from "../_sub-forms/legal-agreement/validation-schema";
export const validationSchema = yup.object({
    [CUSTOMER]: customerDataValidationSchema,
    [PAYMENT_METHOD]: paymentMethodValidationSchema,
    [LEGAL_AGREEMENT]: legalAgreementValidationSchema
});
