import { StatusType } from "../../services/firebase/auth-context/reducer";
import { SubscriptionStatus, SubscriptionType } from "./enums";
import useLatestEditionSpotlightArticleIds from "../../hooks/use-latest-edition-spotlight";
export const hasActiveSubscription = claims => {
    return claims && claims.status === StatusType.SUCCESS && isActiveSubscriptionByStatus(claims.data.subscriptionStatus) && isActiveSubscriptionByEnd(claims.data.subscriptionEnd);
};
const isActiveSubscriptionByStatus = status => {
    return status === SubscriptionStatus.ACTIVE || status === SubscriptionStatus.TRIAL;
};
const isActiveSubscriptionByEnd = subscriptionEnd => {
    return subscriptionEnd && subscriptionEnd >= new Date();
};
export const hasActiveSocialSubscription = claims => {
    return hasActiveSubscription(claims);
};
export const hasActiveNormalSubscription = claims => {
    return hasActiveSubscription(claims) && claims.status === StatusType.SUCCESS && [SubscriptionType.NORMAL, SubscriptionType.PLUS, SubscriptionType.SUPPORTER].includes(claims.data.subscriptionType);
};
export const hasActivePlusSubscription = claims => {
    return hasActiveSubscription(claims) && claims.status === StatusType.SUCCESS && [SubscriptionType.PLUS, SubscriptionType.SUPPORTER].includes(claims.data.subscriptionType);
};
export const hasActiveSupporterSubscription = claims => {
    return hasActiveSubscription(claims) && claims.status === StatusType.SUCCESS && claims.data.subscriptionType === SubscriptionType.SUPPORTER;
};
export const hasAccessToArticle = (claims, authenticated, visibility, articleId, email) => {
    if (accessByMail(email))
        return true;
    // Article is open for everyone.
    if (articleVisibilityIsOpen(articleId, visibility))
        return true;
    // Article is open for all subscribers (or visibility is undefined).
    if (authenticated && (!visibility || articleVisibilityIsClosed(articleId, visibility)) && hasActiveSubscription(claims))
        return true;
    // Article is open for plus subscribers.
    return authenticated && articleVisibilityIsPlus(visibility) && hasActivePlusSubscription(claims);
};
const articleBelongsToLatestEditionOrSpotlight = articleId => {
    const latestArticleIds = useLatestEditionSpotlightArticleIds();
    if (latestArticleIds.edition && latestArticleIds.edition.articles.includes(articleId))
        return true;
    return latestArticleIds.spotlight && latestArticleIds.spotlight.articles.includes(articleId);
};
export const articleVisibilityIsAlwaysOpen = visibility => visibility === "Immer offen";
export const articleVisibilityIsOpen = (articleId, visibility) => articleVisibilityIsAlwaysOpen(visibility) || visibility === "Offen" && articleBelongsToLatestEditionOrSpotlight(articleId);
export const articleVisibilityIsClosed = (articleId, visibility) => visibility === "Geschlossen" || visibility === "Offen" && !articleBelongsToLatestEditionOrSpotlight(articleId);
export const articleVisibilityIsPlus = visibility => visibility === "Abo+";
export const isEditorialOrBriefing = articleType => articleType == "Editorial" || articleType == "Briefing";
export const accessByMail = email => {
    if (email) {
        return ["hallo@makroskop.eu", "paul.steinhardt@makroskop.eu", "verlagsleitung@makroskop.eu", "administration@makroskop.eu", "sebastian.mueller@makroskop.eu", "info@mem-wirtschaftsethik.de", "mail@ericbonse.eu", "berthold.vogel@sofi.uni-goettingen.de", "raimund.dietz@provollgeld.at", "ws@mpifg.de", "hansjoerg.herr@hwr-berlin.de", "gum-heine@t-online.de", "p.u.menzel@t-online.de", "pmlingens00@gmail.com", "jbibow@skidmore.edu", "guenther.grunert@osnanet.de", "a.noelke@soz.uni-frankfurt.de", "eder-berlin@t-online.de", "hardy.koch@yahoo.fr", "hflassbeck@gmail.com", "drjensaholst@web.de", "gpappin@americanaffairsjournal.org", "reinhardlange@gmx.net", "heinz.bontrup@t-online.de", "beppler-spahl@web.de", "michael.paetz@uni-hamburg.de", "dirk@ehnts.de", "bernward.janzing@t-online.de", "hendrikhinrichs@gmx.net", "g.czisch@transnational-renewables.org", "hoepner@mpifg.de", "jochem@erikjochem.de", "apo18@uni-koeln.de", "marcel.kunzmann@gmail.com", "christian.person@tu-darmstadt.de", "jsbwolz@web.de", "richard.senner@gmx.de", "werner.vontobel@ringier.ch", "w.tobergte@gmx.de", "dieter.schlatter@cern.ch", "pewa1@icloud.com", "peter.wahl@weed-online.org", "steffenstierle@hotmail.com", "steffen.stierle@lexit-network.org", "joerke@pg.tu-darmstadt.de", "p.u.menzel@t-online.de", "matthias_garscha@yahoo.de", "danieldeimling83@gmail.com", "isabell.kieser@gmx.de", "sascha.beele123@gmail.com", "stefan@fuhrer.at", "helge.peukert@uni-siegen.de", "gerhard.bosch@uni-due.de", "mglieb85@gmail.com", "contact@michael-von-der-schulenburg.com", "ev.tsakiridou@gmx.de", "josephkuhn@web.de", "future_primitive82@yahoo.it", "vingo2111@gmail.com", "daniel.matthews-ferrero@outlook.com", "marcus.obrecht@politik.uni-freiburg.de", "raimund.dietz@provollgeld.at", "koppetsch@ifs.tu-darmstadt.de", "l.heckmann@outlook.de", "mail@fritz-reheis.de", "maltekornfeld@web.de", "walter-pfannkuche@uni-kassel.de",
            // via Code
            "dr.dieter.stolz@gmail.com", "udoklaesener@t-online.de", "syb_schenk@t-online.de", "eflehner@aon.at", "nbernholt@t-online.de", "andreasborrmann1@gmail.com", "info.krevet@web.de", "jan-klinger@web.de"].includes(email.toLowerCase());
    }
    return false;
};
