export const SUBSCRIPTION_TYPE = "subscription_type";
export { DURATION } from "../_sub-forms/duration/field-names";
export const SUBSCRIPTION_DURATION = "subscription_duration";
export { AMOUNT } from "../_sub-forms/supporters-amount/field-names";
export const SUPPORTERS_SUBSCRIPTION = "supporters_subscription";
export { SALUTATION, FIRST_NAME, LAST_NAME, EMAIL, STREET, POSTAL_CODE, CITY, COUNTRY } from "../_sub-forms/customer-data/field-names";
export const CUSTOMER = "customer";
export { METHOD, SEPA_ACCOUNT_OWNER, SEPA_IBAN, SEPA_BIC, SEPA_BANK, STRIPE_TOKEN } from "../_sub-forms/payment-method/field-names";
export const PAYMENT_METHOD = "payment_method";
export { NEWSLETTER } from "../_sub-forms/newsletter-agreement/field-names";
export const NEWSLETTER_AGREEMENT = "newsletter";
export { AGB, PRIVACY } from "../_sub-forms/legal-agreement/field-names";
export const LEGAL_AGREEMENT = "legal";
